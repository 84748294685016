import React, { useState, useEffect } from "react";
import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';

const Loader = () => (
  <div className="d-flex justify-content-center align-items-center my-3">
    <div className="spinner-grow text-primary mx-1" role="status"></div>
    <div className="spinner-grow text-secondary mx-1" role="status"></div>
    <div className="spinner-grow text-success mx-1" role="status"></div>
  </div>
);

const ActiveJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState(false);
  const [fileUploading, setFileUploading] = useState(false); // State for file uploading
  const [pcdLoading, setPcdLoading] = useState({}); // State for PCD loader for each job
  const [selectedJobId, setSelectedJobId] = useState(null); 
  const [fileType, setFileType] = useState("");
  const [file, setFile] = useState(null); 
  const [showModal, setShowModal] = useState(false);

  const fetchJobs = async () => {
    setJobLoading(true);
    try {
      const response = await axios.get(
         `${process.env.REACT_APP_API_BASE_URL}/job/jobs/byStatus?status=inprogress`
      );
      setJobs(response.data.jobs);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      alert("Failed to fetch jobs. Please try again.");
    } finally {
      setJobLoading(false);
    }
  };

  const createJob = async () => {
    setLoading(true);
    try {
      const response = await axios.post( `${process.env.REACT_APP_API_BASE_URL}/job/jobs`, {
        status: "inprogress",
        createdBy: "Khalil Ur Rehman",
      });
      alert("Job created successfully!");
      fetchJobs();
    } catch (error) {
      console.error("Error creating job:", error);
      alert("Failed to create job. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async () => {
    if (!file || !fileType || !selectedJobId) {
      alert("Please select a file type and upload a file.");
      return;
    }

    const urlMapping = {
      Product:  `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UploadPimDataToDb`,
      BomProduct:  `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UploadBomDataToDb`,
      IMR:  `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UploadInventoryDataToDb`,
      DSR:  `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UploadDSRDataToDb`,
      Dementra:  `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UploadDemantraDataToDb`,
      UnsubmittedCart:  `${process.env.REACT_APP_API_BASE_URL}/dataPosting/UploadUnsubmittedCartDataToDb`,
    };

    const uploadUrl = urlMapping[fileType];
    const formData = new FormData();
    formData.append("Files", file);
    formData.append("jobId", selectedJobId);

    setFileUploading(true); // Start file uploading state
    try {
      await axios.post(uploadUrl, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      alert("File uploaded successfully!");
      fetchJobs();
      setShowModal(false);
      setFile(null);
      setFileType("");
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file. Please try again.");
    } finally {
      setFileUploading(false); // End file uploading state
    }
  };

  const callParentChildDistroApi = async (jobId) => {
    setPcdLoading((prevState) => ({ ...prevState, [jobId]: true })); // Set loading state for the specific jobId
    try {
      await axios.post(
         `${process.env.REACT_APP_API_BASE_URL}/parentChildDistro/CreateParentChildDistro`,
        {
          jobId: jobId.toString(),
        }
      );
      alert("Parent-Child Distribution API called successfully!");
    } catch (error) {
      console.error("Error calling Parent-Child Distribution API:", error);
      alert("Failed to call API. Please try again.");
    } finally {
      setPcdLoading((prevState) => ({ ...prevState, [jobId]: false })); // Reset loading state for the specific jobId
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  return (
    <div className="container mt-4">
      <h2 className="text-center">Active Jobs</h2>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <button
          className="btn btn-primary"
          onClick={createJob}
          disabled={loading}
        >
          {loading ? "Processing..." : "Create Job"}
        </button>
        {jobLoading && <Loader />}
      </div>

      <table className="table table-bordered table-striped table-hover">
        <thead className="table-dark">
          <tr>
            <th>Job ID</th>
            <th>Created By</th>
            <th>Time</th>
            <th>Product</th>
            <th>BOM</th>
            <th>IMR</th>
            <th>DSR</th>
            <th>Dementra</th>
            <th>UCD</th>
            <th>PCD</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {jobs.length > 0 ? (
            jobs.map((job) => (
              <tr key={job.jobId}>
                <td>{job.jobId}</td>
                <td>{job.createdBy}</td>
                <td>{new Date(job.createdAt).toLocaleString()}</td>
                <td>{job.isProduct ? "Yes" : "No"}</td>
                <td>{job.isBom ? "Yes" : "No"}</td>
                <td>{job.isIMR ? "Yes" : "No"}</td>
                <td>{job.isDSR ? "Yes" : "No"}</td>
                <td>{job.isDementra ? "Yes" : "No"}</td>
                <td>{job.isUCR ? "Yes" : "No"}</td>
                <td>
                  {job.isProduct && job.isBom && job.isIMR && job.isDSR && job.isDementra && job.isUCR ? (
                    <button
                      className="btn btn-warning"
                      onClick={() => callParentChildDistroApi(job.jobId)}
                      disabled={pcdLoading[job.jobId]} // Disable the button when loading
                    >
                      {pcdLoading[job.jobId] ? <Loader /> : "PCD"}
                    </button>
                  ) : (
                    "N/A"
                  )}
                </td>
                <td>{job.status}</td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      setSelectedJobId(job.jobId);
                      setShowModal(true);
                    }}
                  >
                    Upload File
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11" className="text-center">
                No jobs found
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Modal for File Upload */}
      {showModal && (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Upload File</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setShowModal(false);
                    setFile(null);
                    setFileType("");
                  }}
                ></button>
              </div>
              <div className="modal-body">
                {fileUploading ? (
                  <Loader />
                ) : (
                  <>
                    <p>Job ID: {selectedJobId}</p>
                    <div className="mb-3">
                      <label className="form-label">File Type:</label>
                      <select
                        className="form-select"
                        value={fileType}
                        onChange={(e) => setFileType(e.target.value)}
                      >
                        <option value="">Select File Type</option>
                        <option value="Product">Product</option>
                        <option value="BomProduct">BomProduct</option>
                        <option value="IMR">IMR</option>
                        <option value="DSR">DSR</option>
                        <option value="Dementra">Dementra</option>
                        <option value="UnsubmittedCart">UnsubmittedCart</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">File:</label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary me-2"
                        onClick={handleFileUpload}
                      >
                        Upload
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={() => {
                          setShowModal(false);
                          setFile(null);
                          setFileType("");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveJobs;
