import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import pamperedChefLogo from '../assets/pampered-chef-logo.jpg';  // Import the image

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    const success = login(email, password);
    if (success) {
      navigate('/dashboard'); // Redirect to dashboard after login
    } else {
      setError('Invalid email or password');
    }
  };

  return (
    <div className="d-flex vh-100">
      {/* Left Side */}
      <div className="d-flex flex-column justify-content-center align-items-center bg-white" style={{ flex: 1 }}>
        <div className="text-center">
          <div
            style={{
              fontFamily: 'serif',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              fontSize: '40px', // Adjust the font size
            }}
          >
            {/* Add your image here */}
            <img
              src={pamperedChefLogo}
              alt="Pampered Chef Logo"
              style={{
                width: '350px', // Same as font size
                height: '100px', // Same as font size
                marginRight: '10px', // Add space between image and text
              }}
            />
          </div>
          <p className="text-muted" style={{ marginTop: '35px' }}>Inventory Optimization</p>
        </div>
        <p
          className="text-center text-muted"
          style={{
            fontStyle: 'italic',
            color: '#D6B2A1',
            maxWidth: '300px',
            marginTop: '80px',
            fontWeight: 'normal',
            fontSize: '16px',
          }}
        >
          Enriching lives, one meal and one memory at a time.
        </p>
      </div>

      {/* Right Side */}
      <div className="d-flex justify-content-center align-items-center bg-light" style={{ flex: 1 }}>
        <div className="p-4 shadow-sm" style={{ maxWidth: '400px', width: '100%' }}>
          <form onSubmit={handleLogin}>
            <div className="mb-3">
              <input
                type="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
              />
            </div>
            {error && <div className="alert alert-danger text-center">{error}</div>}
            <button type="submit" className="btn btn-teal w-100 mb-2" style={{ backgroundColor: '#006d5b', color: 'white' }}>
              Login
            </button>
          </form>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-link text-muted p-0 mb-2"
              style={{ marginTop: '30px' }}
              onClick={() => alert('Forgot Password?')}
            >
              Forgotten Password?
            </button>
          </div>
          <div className="text-center">
            <button
              className="btn w-50"  // Changed to w-50 to make it 50% width
              style={{ backgroundColor: '#008c7a', color: 'white', marginTop: '30px', paddingTop: '10px' }}
              onClick={() => alert('Request Access')}
            >
              Request Access
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
