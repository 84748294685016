import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import logo from '../assets/pampered-chef-logo.jpg'; // Import the logo image

const Sidebar = () => {
  return (
    <div className="d-flex flex-column bg-light shadow p-3" style={{ width: '200px', height: '100vh' }}>
      {/* Logo with Dashboard Link */}
      <div className="text-center mb-4">
        <Link to="/dashboard">
          <img
            src={logo}
            alt="Logo"
            style={{
              width: '170px', // Adjust the size of the logo
              height: '50px', // Adjust the size of the logo
              cursor: 'pointer', // Change the cursor to a pointer to indicate it's clickable
            }}
          />
        </Link>
      </div>

      {/* Sidebar Links */}
      <Link
        to="/dashboard"
        className="text-dark mb-3 fs-5 text-decoration-none d-block text-center"
        style={{
          backgroundColor: '#E9C99C', // Background color for the link
          borderRadius: '5px', // Optional: add rounded corners to the background
          padding: '10px', // Adjust the size of the link to match a button
          textAlign: 'center', // Center the text inside the link
        }}
      >
        Dashboard
      </Link>
      <Link
        to="/active-jobs"
        className="text-dark mb-3 fs-5 text-decoration-none d-block text-center"
        style={{
          backgroundColor: '#E9C99C', // Background color for the link
          borderRadius: '5px', // Optional: add rounded corners to the background
          padding: '10px', // Adjust the size of the link to match a button
          textAlign: 'center', // Center the text inside the link
        }}
      >
        Active Jobs
      </Link>
      <Link
        to="/history"
        className="text-dark mb-3 fs-5 text-decoration-none d-block text-center"
        style={{
          backgroundColor: '#E9C99C', // Background color for the link
          borderRadius: '5px', // Optional: add rounded corners to the background
          padding: '10px', // Adjust the size of the link to match a button
          textAlign: 'center', // Center the text inside the link
        }}
      >
        History
      </Link>
    </div>
  );
};

export default Sidebar;
