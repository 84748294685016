import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const History = () => {
  const [date, setDate] = useState(''); // State for selected date
  const [data, setData] = useState([]); // State to store job data
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  // Function to fetch jobs from the API
  const fetchJobs = async (selectedDate = '') => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/job/jobs`, {
        params: { date: selectedDate }, // Send selected date as query parameter
      });
      setData(response.data); // Store the fetched data
    } catch (error) {
      console.error('Error fetching jobs:', error);
      setError('Failed to fetch job history. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch jobs on component mount or when the date changes
  useEffect(() => {
    fetchJobs(date);
  }, [date]);

  return (
    <div className="container my-5">
      <h2 className="mb-4 text-center">Job History</h2>

      {/* Date Picker */}
      <div className="mb-4">
        <label htmlFor="date" className="form-label">
          Select Date:
        </label>
        <input
          type="date"
          id="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="form-control"
        />
      </div>

      {/* Loading or Error State */}
      {loading ? (
        <div className="text-center my-4">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : error ? (
        <div className="alert alert-danger text-center my-4">{error}</div>
      ) : (
        // Job Table
        <div className="table-responsive">
          <table className="table table-striped table-hover table-bordered">
            <thead className="table-dark">
              <tr>
                <th>Job ID</th>
                <th>Created By</th>
                <th>Created At</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0 ? (
                data.map((job, index) => (
                  <tr key={index}>
                    <td>{job.jobId || 'N/A'}</td>
                    <td>{job.createdBy || 'N/A'}</td>
                    <td>{new Date(job.createdAt).toLocaleString() || 'N/A'}</td>
                    <td>{job.status || 'N/A'}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No job history found for the selected date.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default History;
