import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Header = () => {
  const { logout } = useAuth(); // Get the logout function from the Auth context
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); // Clear authentication
    navigate('/login'); // Redirect to login page
  };

  return (
    <header className="d-flex justify-content-between align-items-center p-3 bg-light border-bottom">
      <h1 className="h3 m-0"></h1>
      <button
        className="btn btn-danger btn-sm"
        onClick={handleLogout}
      >
        Logout
      </button>
    </header>
  );
};

export default Header;
